import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'gatsby';

import AddToCarts from '@components/blog-details/buttons/addToCarts';
import Facebook from '@assets/book-details/facebookIcon';
import Twitter from '@assets/social-icons/twitter';
import LinkedIn from '@assets/social-icons/linkedin';
import LeftArrowContact from '@assets/contact/left-arrow';
import increment from '@assets/book-details/increment.svg';
import decrement from '@assets/book-details/decrement.svg';
import CartContext from '../../context/cart-context';
import BlogButton from '@components/reusable-buttons/blog-button';
import RequestForm from '../book/request-form-outside';
import LanguageContext from '../../context/language-context';
import { useLayoutEffect } from 'react';

export default function BookDetailsHero({ bookInformations, id, shopifyId }) {
  //just for deploy check
  const { price, bookInfo, title, galleries, coverImageUrl } = bookInformations;

  const { geolocationStatus, getProductById, updateLineItem, setRequestFormBook } = useContext(CartContext);

  const { contentLanguage } = useContext(LanguageContext);

  const [quantity, setQuantity] = useState(1);

  const galleryDisplayImage = coverImageUrl;

  const [dynamicHeading, setDynamicHeading] = useState({
    heading1: '',
    heading2: '',
  });

  const [galleryUrl, setGalleryUrl] = useState({
    galleryDisplayImage,
    clickedImage: 0,
  });

  const componentTitle = title;

  useLayoutEffect(() => {
    document.getElementById('languageButton').style.display = 'none';
  });

  useEffect(() => {
    let wordsInTitle = componentTitle.split(' ');
    let lengthToSplit = Math.floor(wordsInTitle.length / 2);
    let firstHalfOfTitleArray = wordsInTitle.filter((word, idx) => idx < lengthToSplit);
    let secondHalfOfTitleArray = wordsInTitle.filter((word, idx) => idx > lengthToSplit - 1);
    let heading1 = firstHalfOfTitleArray.join(' ');
    let heading2 = secondHalfOfTitleArray.join(' ') + '.';
    setDynamicHeading(() => ({
      heading1,
      heading2,
    }));
  }, []);

  const [showRequestForm, setRequestForm] = useState(false);
  function avoidScroll() {
    var x = window.scrollX;
    var y = window.scrollY;
    window.onscroll = function () {
      window.scrollTo(x, y);
    };
  }
  function enableScroll() {
    window.onscroll = function () {};
  }

  const [product, setProduct] = useState(null);

  const [selectedVariant, setSelectedVariant] = useState(null);

  useEffect(() => {
    getProductById(shopifyId).then((result) => {
      setProduct(result);
      setSelectedVariant(result.variants[0]);
    });
  }, [getProductById, setProduct, shopifyId]);

  const handleQuantityIn = (id) => {
    setQuantity(quantity + 1);
  };
  const handleQuantityDec = (id) => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  const handleAddToCart = () => {
    updateLineItem({ variantId: selectedVariant.id, quantity: parseInt(quantity, 10) });
  };

  return (
    <div className="book-details-hero-outermost u-margin-top-lg">
      <div className="book-details-hero-container common-container ">
        <ul className="privacy-policy-breadcrumb book-details-breadcrumb">
          <Link to="/">
            <li className="opacity-low">{contentLanguage === 'eng' ? 'Home' : 'Hogar'}</li>
          </Link>
          <LeftArrowContact />
          <Link to="/books">
            <li className="opacity-low">{contentLanguage === 'eng' ? 'Books' : 'Libros'}</li>
          </Link>
          <LeftArrowContact />
          <li>{contentLanguage === 'eng' ? 'Books Details' : 'Detalles de libros'}</li>
        </ul>

        <div className="book-details-content-holder">
          <div className="books-details-content-text">
            <h1>{dynamicHeading.heading1}</h1>
            <h1 className="h1-modified">{dynamicHeading.heading2}</h1>
            <p className="body2">{bookInfo}</p>
            <h5>{geolocationStatus ? (price ? '$' + price : 'NA') : ''}</h5>
            {geolocationStatus ? (
              <div className="books-quantity-controller">
                <div
                  className="quantity-in-decrementer"
                  onClick={() => {
                    handleQuantityDec(id);
                  }}
                >
                  <img src={decrement} alt="minus" />
                </div>
                <p>{quantity}</p>
                <div
                  className="quantity-in-decrementer"
                  onClick={() => {
                    handleQuantityIn(id);
                  }}
                >
                  <img src={increment} alt="plus" />
                </div>
              </div>
            ) : (
              ''
            )}

            {geolocationStatus ? (
              <div className="cart-button-holder-books" onClick={() => handleAddToCart()}>
                <AddToCarts buttonText={contentLanguage === 'eng' ? 'Add To Cart' : 'Agregar al Carrito'} />
              </div>
            ) : (
              <div
                role="button"
                onKeyPress={() => null}
                tabIndex={-2}
                onClick={() => {
                  setRequestForm(true);
                  setRequestFormBook(title);
                  avoidScroll();
                }}
                className="button-holder"
              >
                <BlogButton buttonText={contentLanguage === 'eng' ? 'Request This Book' : 'Solicitar este libro'} />
              </div>
            )}

            <div className="social-icons-share">
              <h6>{contentLanguage === 'eng' ? 'Share this item' : 'Comparte este objeto'}</h6>
              <div className="social-icons-container">
                <a href="https://www.facebook.com/">
                  <Facebook color="black" />
                </a>
                <a href="https://www.twitter.com/">
                  <Twitter color="black" />
                </a>
                <a href="https://www.linkedin.com/">
                  <LinkedIn color="black" />
                </a>
              </div>
            </div>
          </div>
          <div className="books-details-content-image books-details-image-modified">
            <div className="book-details-image-holder">
              <img src={galleryUrl.galleryDisplayImage} alt="chosen book" />
            </div>
            <div className="book-details-book-pages common-container">
              {galleries?.map((image, idx) => {
                const galleryImageUrl = image.galleryImage.fluid;

                if (!galleryImageUrl) {
                  return <></>;
                }

                return (
                  <div
                    className={`book-details-book-pages-container ${galleryUrl.clickedImage === idx ? 'active' : ''}`}
                    key={idx}
                    onClick={() => {
                      setGalleryUrl({
                        galleryDisplayImage: galleryImageUrl.src,
                        clickedImage: idx,
                      });
                    }}
                  >
                    <img className="book-pages" src={galleryImageUrl?.src} alt="page1" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className={showRequestForm ? 'request-form-holder show' : 'hide'}>
        <RequestForm enableScroll={enableScroll} showFunction={setRequestForm} showStatus={showRequestForm} />
      </div>
    </div>
  );
}
