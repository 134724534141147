import React from 'react';

export default function Globe() {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6699 22C18.4657 22 23.1641 17.5228 23.1641 12C23.1641 6.47715 18.4657 2 12.6699 2C6.87418 2 2.17578 6.47715 2.17578 12C2.17578 17.5228 6.87418 22 12.6699 22Z"
        stroke="#F15E32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2.17578 12H23.1641" stroke="#F15E32" strokWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12.6684 2C15.2932 4.73835 16.785 8.29203 16.866 12C16.785 15.708 15.2932 19.2616 12.6684 22C10.0435 19.2616 8.55177 15.708 8.4707 12C8.55177 8.29203 10.0435 4.73835 12.6684 2V2Z"
        stroke="#F15E32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
