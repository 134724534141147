import React from 'react';

export default function Pen() {
  return (
    <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.502 19L20.8479 12L23.9961 15L16.6502 22L13.502 19Z"
        stroke="#F15E32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7985 13L18.2243 5.5L3.00781 2L6.68077 16.5L14.5514 18L19.7985 13Z"
        stroke="#F15E32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.00781 2L10.9687 9.586"
        stroke="#F15E32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4523 13C13.6115 13 14.5512 12.1046 14.5512 11C14.5512 9.89543 13.6115 9 12.4523 9C11.2932 9 10.3535 9.89543 10.3535 11C10.3535 12.1046 11.2932 13 12.4523 13Z"
        stroke="#F15E32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
