import { graphql } from 'gatsby';
import React, { useState, useContext } from 'react';

import Layout from '@layouts';
import Seo from '@layouts/common/seo';
import CartContext from '../../context/cart-context';
import AboutAuthor from '@components/book/about-author';
import RelatedBooks from '@components/blog-details/related-books';
import BookInfoAndVideo from '@components/book-details/book-info-video';
import BookDetailsHero from '@components/book-details/book-details-hero';
import BookPurchaseRequest from '@components/book/book-purchase-request';
import BookAdditionalInfo from '@components/book-details/book-additional-info';

export default function BooksDetails({ data }) {
  const metaDescription = data.prismicBooks?.data?.metaDescription;
  const metaTitle = data.prismicBooks?.data?.metaTitle;
  const getThisBookText = data.prismicBooks?.data?.getThisBook;
  const galleries = data.prismicBooks?.data.gallery;
  const productDetails = data.prismicBooks?.data?.productDetails;
  const bookPages = data.prismicBooks?.data?.body;
  const relatedBooks = data.prismicBooks?.data.relatedBooks;
  let aboutAuthor = '';

  if (!!productDetails) {
    aboutAuthor = productDetails[0]?.author;
  }

  const coverImageUrl = data.shopifyProduct.images[0]?.src;
  const bookInfo = data.shopifyProduct?.description;
  const price = data.shopifyProduct.priceRangeV2?.maxVariantPrice?.amount;
  const title = data.shopifyProduct.title;
  const id = data.shopifyProduct.id;
  const bookInformations = { price, bookInfo, coverImageUrl, title, galleries };
  const { cartData } = useContext(CartContext);
  const shopifyId = data?.shopifyProduct?.shopifyId;
  const uid = data.shopifyProduct?.handle;
  let url = process.env.CLIENT_URL + 'books/' + uid;

  return (
    <Layout>
      <Seo title={metaTitle?.text} description={metaDescription?.text} siteUrl={url} seoImage={coverImageUrl} />
      <BookDetailsHero price bookInfo={bookInfo} bookInformations={bookInformations} id={id} shopifyId={shopifyId} />
      <BookInfoAndVideo data={data} productDetails={productDetails} bookDescription={bookInfo} />
      <BookAdditionalInfo bookPages={bookPages} />
      <AboutAuthor aboutAuthor={aboutAuthor} />
      <BookPurchaseRequest
        imageUrl={coverImageUrl}
        price={price}
        id={id}
        bookInformations={bookInformations}
        getThisBookText={getThisBookText?.text}
        shopifyId={shopifyId}
      />
      <div className="common-container book-details-related-books">
        {relatedBooks && <RelatedBooks books={relatedBooks} />}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query BooksQuery($handle: String) {
    shopifyProduct(handle: { eq: $handle }) {
      images {
        src
      }
      id
      handle
      shopifyId
      title
      priceRangeV2 {
        maxVariantPrice {
          amount
        }
      }
      description
    }

    prismicBooks(uid: { eq: $handle }) {
      data {
        body {
          ... on PrismicBooksBodyDesciptionText {
            id
            items {
              single_book_description {
                raw
              }
            }
            contentType: slice_type
          }
          ... on PrismicBooksBodyPhoto {
            id
            slice_type
            items {
              books_images {
                fluid {
                  src
                }
              }
            }
          }
          ... on PrismicBooksBodyVideo {
            id
            slice_type
            primary {
              video_source {
                embedUrl: embed_url
              }
            }
          }
        }
        relatedVideos: related_videos {
          videoLink: video_link {
            embedUrl: embed_url
          }
        }
        relatedBooks: related_books {
          relatedBook: related_book {
            document {
              ... on PrismicBooks {
                uid
                id
                data {
                  bookInfo: book_info {
                    text
                    raw
                  }
                  price {
                    text
                  }
                  coverImage: cover_image {
                    fluid {
                      src
                    }
                  }
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        coverImage: cover_image {
          fluid {
            src
          }
        }
        bookInfo: book_info {
          text
        }
        price {
          text
        }
        title {
          text
        }
        metaTitle: meta_title {
          text
        }
        metaDescription: meta_description {
          text
        }
        productDetails: product_details {
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  title {
                    text
                  }
                  description {
                    text
                  }
                  language
                  headshot {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
          dimensions {
            text
          }
          isbn {
            text
          }
          language {
            text
          }
          printLength: print_length {
            text
          }
          publicationDate: publication_data {
            text
          }
        }
        getThisBook: get_this_book {
          text
        }
        gallery {
          galleryImage: gallery_image {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`;
