import React from 'react';

export default function Box() {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.6825 16V8.00002C22.6821 7.6493 22.5849 7.30483 22.4007 7.00119C22.2165 6.69754 21.9518 6.44539 21.633 6.27002L14.2871 2.27002C13.9681 2.09449 13.6061 2.00208 13.2377 2.00208C12.8693 2.00208 12.5074 2.09449 12.1883 2.27002L4.84238 6.27002C4.52364 6.44539 4.25889 6.69754 4.0747 7.00119C3.8905 7.30483 3.79335 7.6493 3.79297 8.00002V16C3.79335 16.3508 3.8905 16.6952 4.0747 16.9989C4.25889 17.3025 4.52364 17.5547 4.84238 17.73L12.1883 21.73C12.5074 21.9056 12.8693 21.998 13.2377 21.998C13.6061 21.998 13.9681 21.9056 14.2871 21.73L21.633 17.73C21.9518 17.5547 22.2165 17.3025 22.4007 16.9989C22.5849 16.6952 22.6821 16.3508 22.6825 16Z"
        stroke="#F15E32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.07617 6.95996L13.2376 12.01L22.399 6.95996"
        stroke="#F15E32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13.2383 22.08V12" stroke="#F15E32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
