import React from 'react';

export default function BookIcon() {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.80273 19.5C4.80273 18.837 5.07914 18.2011 5.57115 17.7322C6.06316 17.2634 6.73047 17 7.42627 17H21.5934"
        stroke="#F15E32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.42627 2H21.5934V22H7.42627C6.73047 22 6.06316 21.7366 5.57115 21.2678C5.07914 20.7989 4.80273 20.163 4.80273 19.5V4.5C4.80273 3.83696 5.07914 3.20107 5.57115 2.73223C6.06316 2.26339 6.73047 2 7.42627 2V2Z"
        stroke="#F15E32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
