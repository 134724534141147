import React from 'react';

export default function Calender() {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.2301 4H5.53829C4.37913 4 3.43945 4.89543 3.43945 6V20C3.43945 21.1046 4.37913 22 5.53829 22H20.2301C21.3893 22 22.3289 21.1046 22.3289 20V6C22.3289 4.89543 21.3893 4 20.2301 4Z"
        stroke="#F15E32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M17.082 2V6" stroke="#F15E32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.68555 2V6" stroke="#F15E32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.43945 10H22.3289" stroke="#F15E32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
