import React, { useContext } from 'react';
import ReactPlayer from 'react-player';

import BookIcon from '@assets/book-details/book-icon';
import Calender from '@assets/book-details/calender';
import Globe from '@assets/book-details/globe';
import ISBN from '@assets/book-details/ISBN';
import Box from '@assets/book-details/box';
import Pen from '@assets/book-details/pen';
import LanguageContext from '../../context/language-context';

export default function BookInfoAndVideo({ data, productDetails, bookDescription }) {
  const videoUrl = data.prismicBooks?.data.relatedVideos;
  if (!videoUrl) {
    return <></>;
  }
  const embedUrl = videoUrl[0]?.videoLink;

  const { dimensions, isbn, author, language, printLength, publicationDate } = productDetails[0];
  const authorName = author?.document?.data?.title?.text;

  if (!embedUrl) {
    return <></>;
  }

  const { contentLanguage } = useContext(LanguageContext);

  return (
    <div className="book-info-video-outermost-container">
      <div className="book-info-video-content common-container">
        <div className="book-details-book-info">
          <div className="book-infos">
            <h6 className="book-info-text desktop-button">
              {contentLanguage === 'eng' ? ' Print-Lenth' : 'Longitud de impresión'}
            </h6>

            <BookIcon />
            <h6 className="book-info-text "> {printLength?.text}</h6>
          </div>
          <div className="book-infos">
            <h6 className="book-info-text desktop-button">
              {contentLanguage === 'eng' ? 'Publication Date' : 'Fecha de publicación'}
            </h6>

            <Calender />

            <h6 className="book-info-text "> {publicationDate?.text}</h6>
          </div>
          <div className="book-infos">
            <h6 className="book-info-text desktop-button">{contentLanguage === 'eng' ? 'Language' : 'Idioma'}</h6>

            <Globe />

            <h6 className="book-info-text"> {language?.text}</h6>
          </div>
          <div className="book-infos">
            <h6 className="book-info-text desktop-button">{contentLanguage === 'eng' ? ' Authors' : 'Autores'}</h6>

            <Pen />

            <h6 className="book-info-text "> {authorName}</h6>
          </div>
          <div className="book-infos">
            <h6 className="book-info-text desktop-button">
              {contentLanguage === 'eng' ? 'Dimensions' : 'Dimensiones'}
            </h6>

            <Box />

            <h6 className="book-info-text ">{dimensions?.text}</h6>
          </div>
          <div className="book-infos display-none-tab">
            <h6 className="book-info-text desktop-button">ISBN</h6>

            <ISBN />

            <h6 className="book-info-text "> {isbn?.text}</h6>
          </div>
        </div>
        <div className="book-details-text-video-holder">
          <div className="book-details-book-desc">
            <h2>{contentLanguage === 'eng' ? 'Get to know about ' : 'Conoce los libros a '}</h2>
            <h2 className="h2-modified">
              {contentLanguage === 'eng' ? 'the books through this video' : 'través de este video'}
            </h2>
            <p className="body1">{bookDescription?.text}</p>
            <p className="body1 u-margin-top-xs">ISBN:{isbn?.text}</p>
          </div>
          <div className="book-details-video">
            <ReactPlayer
              width="100%"
              height="100%"
              url={videoUrl[0]?.videoLink?.embedUrl}
              auto-playing="false"
              controls
            />
          </div>
        </div>
      </div>
    </div>
  );
}
