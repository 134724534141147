import React, { useContext, useState, useEffect } from 'react';

import AddToCarts from '@components/blog-details/buttons/addToCarts';
import CartContext from '../../context/cart-context';
import BlogButton from '@components/reusable-buttons/blog-button';
import RequestForm from './request-form-outside';
import LanguageContext from '../../context/language-context';

export default function BookPurchaseRequest({ imageUrl, getThisBookText, bookInformations, shopifyId }) {
  const { price, title, coverImageUrl } = bookInformations;

  const { geolocationStatus, setRequestFormBook, getProductById, updateLineItem } = useContext(CartContext);
  const { contentLanguage } = useContext(LanguageContext);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);

  const [showRequestForm, setRequestForm] = useState(false);
  function avoidScroll() {
    var x = window.scrollX;
    var y = window.scrollY;
    window.onscroll = function () {
      window.scrollTo(x, y);
    };
  }
  function enableScroll() {
    window.onscroll = function () {};
  }

  useEffect(() => {
    getProductById(shopifyId).then((result) => {
      setProduct(result);
      setSelectedVariant(result.variants[0]);
    });
  }, [getProductById, setProduct, shopifyId]);

  const handleAddToCart = () => {
    updateLineItem({ variantId: selectedVariant.id, quantity: parseInt(quantity, 10) });
  };

  return (
    <div className="book-purchase-req-outermost">
      <div className="book-purchase-req-container common-container">
        <div className="book-buy-req-price">
          <h3>{contentLanguage === 'eng' ? 'Want to get' : 'Quieres conseguir'} </h3>{' '}
          <h3 className="h3-modified">{title}?</h3>
          <p className="body1">{getThisBookText}</p>
          <h5>{price?.text}</h5>
          {geolocationStatus ? (
            <div className="book-purchase-botton-holder" onClick={() => handleAddToCart()}>
              <AddToCarts buttonText={contentLanguage === 'eng' ? 'Add To Cart' : 'Agregar al Carrito'} />
            </div>
          ) : (
            <div
              role="button"
              onKeyPress={() => null}
              tabIndex={-2}
              onClick={() => {
                setRequestForm(true);
                setRequestFormBook(title);
                avoidScroll();
              }}
              className="button-holder"
            >
              <BlogButton buttonText={contentLanguage === 'eng' ? 'Request This Book' : 'Solicitar este libro'} />
            </div>
          )}
        </div>
        <div className="book-to-purchase-image">
          <img src={imageUrl} />
        </div>
      </div>
      <div className={showRequestForm ? 'request-form-holder show' : 'hide'}>
        <RequestForm enableScroll={enableScroll} showFunction={setRequestForm} showStatus={showRequestForm} />
      </div>
    </div>
  );
}
