import React, { useContext } from 'react';

import LanguageContext from '../../context/language-context';

export default function AboutAuthor({ aboutAuthor }) {
  if (!aboutAuthor.document) return <></>;

  const { title, description, designation, headshot } = aboutAuthor?.document?.data;
  const authorName = title?.text;
  const authorPhoto = headshot?.fluid.src;
  const { contentLanguage } = useContext(LanguageContext);
  return (
    <div className="about-author-container common-container">
      <div className="author-desc">
        <h2>{contentLanguage === 'eng' ? 'About the' : 'Acerca de'} </h2>
        <h2 className="h2-modified"> {contentLanguage === 'eng' ? 'author' : 'autora'}</h2>
        <p className="body2 about-author-desc">{description.text}</p>
        <h5>{authorName}</h5>
        <h6>{designation?.text} </h6>
      </div>
      <div className="author-photo">
        <img src={authorPhoto}></img>
      </div>
    </div>
  );
}
