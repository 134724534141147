import React from 'react';

export default function ISBN() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.97656 14V17C1.97656 17.5304 2.18728 18.0391 2.56235 18.4142C2.93742 18.7893 3.44613 19 3.97656 19H6.97656M6.97656 1H3.97656C3.44613 1 2.93742 1.21071 2.56235 1.58579C2.18728 1.96086 1.97656 2.46957 1.97656 3V6L6.97656 1ZM19.9766 6V3C19.9766 2.46957 19.7658 1.96086 19.3908 1.58579C19.0157 1.21071 18.507 1 17.9766 1H14.9766L19.9766 6ZM14.9766 19H17.9766C18.507 19 19.0157 18.7893 19.3908 18.4142C19.7658 18.0391 19.9766 17.5304 19.9766 17V14L14.9766 19Z"
        stroke="#F15E32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
