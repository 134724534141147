import React from 'react';

import Image from './image';
import Text from './text';

const getComponentByContentType = (page, index) => {
  switch (page.contentType) {
    case 'desciption_text':
      return (
        <div key={index} className="additional-info-slice-wrapper">
          <Text page={page} />
        </div>
      );
    case 'photo':
      return <Image key={index} className="additional-info-slice-wrapper"></Image>;

    default:
      return null;
  }
};

const BookPages = ({ pages }) => {
  if (!pages) {
    return <></>;
  }
  return pages.map((page, index) => getComponentByContentType(page, index));
};

export default BookPages;
