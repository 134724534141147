import React from 'react';

export default function FacebookIcon() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.9766 2H15.9766C14.6505 2 13.3787 2.52678 12.441 3.46447C11.5033 4.40215 10.9766 5.67392 10.9766 7V10H7.97656V14H10.9766V22H14.9766V14H17.9766L18.9766 10H14.9766V7C14.9766 6.73478 15.0819 6.48043 15.2695 6.29289C15.457 6.10536 15.7113 6 15.9766 6H18.9766V2Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
