import React from 'react';
import BookPages from './slices/slices';

export default function BookAdditionalInfo({ bookPages }) {
  return (
    <div className="book-details-additional-info-holder common-container">
      <div className="book-additional-info-holder">
        <BookPages pages={bookPages} />
      </div>
    </div>
  );
}
