import React, { useContext } from 'react';
import { Link } from 'gatsby';

import { RichText } from 'prismic-reactjs';
import CartContext from '../../context/cart-context';
import LanguageContext from '../../context/language-context';

const RelatedBooks = ({ books, from }) => {
  if (!books.length) {
    return <></>;
  }
  if (from === 'blogs') {
    if (!books[0].similarBook.document) {
      return <></>;
    }
    if (!books[0].relatedBook?.document) {
      return <></>;
    }
  }
  if (!books[0].relatedBook.document) {
    return <></>;
  }

  const { geolocationStatus } = useContext(CartContext);
  const { contentLanguage } = useContext(LanguageContext);

  function avoidScroll() {
    var x = window.scrollX;
    var y = window.scrollY;
    window.onscroll = function () {
      window.scrollTo(x, y);
    };
  }
  function enableScroll() {
    window.onscroll = function () {};
  }

  return (
    <div className="related-books-holder u-margin-top-md common-conatainer">
      <div className="related-books-title">
        <h4>{contentLanguage === 'eng' ? 'Related books' : 'Libros Relacionados'}</h4>
      </div>
      <div className="related-books-inner-wrapper ">
        {books.map((book) => {
          let singleBook;
          let uid;

          if (from === 'blogs') {
            singleBook = book.similarBook?.document?.data;
            uid = book.similarBook?.document?.uid;
          } else {
            singleBook = book.relatedBook.document?.data;
            uid = book.relatedBook?.document?.uid;
          }

          if (!singleBook) {
            return <></>;
          }

          const { bookInfo, coverImage, coverPhoto, bookInformation, title, price } = singleBook;

          return (
            <div className="related-books-inner">
              <div className="related-books-details">
                <div className="related-books-image">
                  <Link to={`/books/${uid}`}>
                    <img src={coverImage?.fluid.src || coverPhoto?.fluid.src} alt="related book" />
                  </Link>
                </div>

                <div className="related-books-description">
                  <div className="related-books-heading ">
                    <h5>{title?.text}</h5>
                  </div>
                  <div className="related-books-about">{RichText.render(bookInfo?.raw || bookInformation?.raw)}</div>
                  <div className="related-books-price">
                    <Link to={`/books/${uid}`}>
                      <div className="related-books-view">
                        {contentLanguage === 'eng' ? 'View Details' : 'Ver Detalles'}
                      </div>
                    </Link>
                    <div>
                      <h5>{geolocationStatus ? price?.text : ''}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RelatedBooks;
